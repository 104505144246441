<template>
  <footer>
    <div class="footerbigbox">
      <div class="footerbox">
        <div class="flex align_center min_width max_width">
          <div class="text">
            <div class="call col_666">联系我们</div>

            <div class="sort"></div>
            <!-- <el-progress :percentage="5" color="#cccccc"></el-progress> -->
            <!-- <div class="sort"></div> -->
            <div
              style="
                padding: 30px 0px;
                width: 700px;
                border-bottom: 1px solid #cccccc;
              "
            >
              <div class="title col_666">指导单位：青海省工业和信息化厅</div>
              <div class="title col_666">主管单位：青海省中小企业发展促进中心</div>
              <div class="title col_666">运营单位：青海经信中小企业服务有限公司</div>
              <div class="title col_666">服务热线：0971-96868</div>
              <div class="title col_666">电子邮箱：qh96868@126.com</div>
            </div>
            <div class="title col_666" style="padding: 30px 0px">
              Copyright（C）2010-2023 www.smeqh.cn
            </div>
          </div>
          <div class="text">
            <div style="display: flex">
              

              <div
                class="sortsbox col_666"
                style="width: 127px; margin-right: 52px"
              >
                微信公众号
                <div class="sorts"></div>
              </div>
              <div
                class="sortsbox col_666"
                style="width: 127px; margin-right: 52px"
              >
                抖音
                <div class="sorts"></div>
              </div>
              <div
                class="sortsbox col_666"
                style="width: 127px; margin-right: 52px"
              >
                视频号
                <div class="sorts"></div>
              </div>
              <div
                class="sortsbox col_666"
                style="width: 127px; margin-right: 52px"
              >
                官方小程序
                <div class="sorts"></div>
              </div>
            </div>
            <div style="border-bottom: 1px solid #cccccc">
              
              <img class="imgs" src="../../assets/image/code.jpg" alt="" />
              <img class="imgs" src="../../assets/image/douyin.png" alt="" />
              <img class="imgs" src="../../assets/image/shipinghao.jpg" alt=""/>
              <img class="imgs" src="../../assets/image/xiaochengxu.jpg" alt="" />
            </div>
            <div class="flex jus_content" style="padding-top: 30px">
              <div class=" foottitle col_666" >

                <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" > <span class="foottitle col_666"> 备案号：青ICP备16000881号-1</span> </a>
              
            </div>
            <div class="flex align_center ">
              <img src="../../assets/image/records_icon.png" style="width: 20px;height: 20px;margin-right: 10px; " alt="">
              <div style="width:300px;">
		 	      	<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=63010402000600" > <span class="foottitle col_666"> 青公网安备 63010402000600号</span> </a>
		       	</div>

            </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      setting: {},
      year: "",
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.imgs {
  width: 127px;
  height: 127px;
  margin: 50px 50px 50px 0px;
}
.footerbigbox {
  // width: 1920px;
  height: 420px;
  background: #e6e6e6;
  .footerbox {
    padding-top: 50px;
    .call {
      border-bottom: 1px solid #cccccc;
      width: 700px;
      padding-bottom: 10px;
      height: 45px;

      .sort {
        border: 2px solid #cccccc;
        position: relative;
        bottom: 3px;
        width: 47px;
      }
    }
  }
}
.text {
  width: 50%;
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #808080;
  line-height: 30px;
}
// ::v-deep .el-progress-bar__outer {
//   height: 2px !important;
// }
// ::v-deep .el-progress__text {
//   display: none;
// }
// ::v-deep .el-progress-bar__inner{

// }

.title {
  width: 700px;
  height: 34px;
  font-size: 18px;
  font-family: Source Han Sans SC Regular, Source Han Sans SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  line-height: 34px;
}

.foottitle{
  height: 34px;
  font-size: 18px;
  font-family: Source Han Sans SC Regular, Source Han Sans SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  line-height: 34px;
}

.sortsbox {
  width: 127px;
  border: 1px;
  margin-right: 52px;
  border-bottom: 1px solid #cccccc;
  /* width: 700px; */
  padding-bottom: 10px;
}
.sorts {
  border: 2px solid #cccccc;
  position: relative;
  top: 10px;
  width: 47px;
}
.col_666 {
  color: #666666;
}
</style>
