<template>
  <!-- <div
    class="fixed bgfff"
    ref="header"
    :style="{ width: $route.path == '/' ? '100%' : 'calc(100% - 15px)' }"
  > -->
  <div class="fixed bgfff" ref="header">
    <header class="padtb_20">
      <div class="max_width flex align_center jus_content min_width">
        <div class="flex align_center cursor" @click="toIndex">
          <img
            src="../../assets/image/Header/logo.png"
            alt="logo"
            class="logo_pic"
          />
          <div class="marl_12">
            <div class="bold size_20 c618">青海省中小企业公共服务平台</div>
            <div class="mart_3 size_14 c618">
              Public service platform for SME of Qinghai Province
            </div>
            <!-- <div class="mart_3 size_14 c618">
              Public Service Platform for Small and Medium-sized Enterprises in
              Qinghai Province
            </div> -->
          </div>
        </div>

        <div class="flex align_center">
          <div class="flex align_center">
            <!-- <img
              src="../../assets/image/Header/kefu.png"
              alt="admin"
              class="icon_pic marr_12"
            /> -->
            <div
              class="size_18 padr_10 marr_12 weight cursor header_hover c618"
              style="border-right: 1px solid #e6e6e6"
            >
              服务热线：{{ phone }}
            </div>
          </div>

          <!--  -->
          <div v-if="!token">
            <el-dropdown @command="golaw" placement="top-end">
              <span
                class="el-dropdown-link size_18 nav_title weight"
                style="color: #000; margin-right: 10px"
              >
                登录 <i class="el-icon-caret-bottom"></i
              ></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="login1"
                  >企业用户登录</el-dropdown-item
                >
                <el-dropdown-item command="login2"
                  >服务机构登录</el-dropdown-item
                >
                <el-dropdown-item command="login3"
                  >服务专员登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div
            v-else
            style="
              display: flex;
              align-items: center;
              right: 10px;
              position: relative;
            "
            @click="gouser"
          >
            <div>
              <i class="el-icon-user-solid" style="font-size: 20px"></i>
            </div>
            <div
              class="flex bold size_18"
              style="
                align-items: center;
                color: #222222;
                margin-right: 10px;
                line-height: 30px;
              "
            >
            
              <span v-if="username">{{ username.substring(0, 15) }}</span>
                <span>{{ userphone }}</span>
                  
             <div class="size_18" style="margin-left: 10px; color: #222222">
                个人中心
              </div>
            </div>
          </div>

          <!-- <div class="flex align_center" v-if="!token" @click="toLoginVue">
            <div class="flex align_center">
              <img
                src="../../assets/image/Header/user.png"
                class="icon_pic iconss"
              />
              <div
                class="text-con ttt size_18 weight cursor header_hover text-con"
              >
                企业登录
              </div>
            </div>
          </div> -->
          <!--  -->
          <div class="flex align_center" v-if="token">
            <div class="" @click="loggin">
              <div
                class="text-con ttt size_18 weight cursor header_hover text-con"
                style="color: #fb1010"
              >
                退出
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <nav class="bgeff">
      <div
        class="flex align_center max_width min_width"
        style="justify-content: space-between"
      >
        <div style="display: flex">
          <!-- <div
            v-for="(item, index) in navList"
            :key="index"
            class="pad_10"
            @click="navigateTo(item.url, item.name)"
          > -->
          <div
            v-for="(item, index) in navList"
            :key="index"
            style="margin: 10px 40px 10px 0px"
            @click="navigateTo(item.url, item.name)"
          >
            <!-- <div>{{ index }}</div>
            <div>{{ poin }}</div> -->
            <div v-if="index == 0" class="flex align_center jus_center">
              <div style="width: 40px; text-align: center;cursor:pointer;" class="fff size_18">
                首页
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
            </div>
            <div v-if="index == 1" class="flex align_center jus_center">
              <div style="text-align: center ;cursor:pointer;" class="fff size_18">
                通知公告
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
            </div>
            <div v-if="index == 2" class="flex align_center jus_center">

              <div style="text-align: center ;cursor:pointer;" class="fff size_18">
                政策法规
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
                <!-- <div class="fff size_18">政策法规</div> -->
                <!-- <i class="el-icon-caret-bottom" style="color: #fff"></i> -->

                <!-- <div :class="{ sele_cursor: poin === index }"></div> -->


                <!-- <el-dropdown @command="golaw" placement="top-end">
                  <div class="bigbox">
                    <span
                      class="el-dropdown-link size_18 nav_title"
                      style="color: #fff; text-align: center"
                    >
                      政策法规
                    </span>
                    <div :class="{ sele_cursor: poin == index }"></div>
                  </div>

                  <el-dropdown-menu slot="dropdown" class="policy">
                    <div class="down">
                      <el-dropdown-item command="one"
                        >政策法规</el-dropdown-item
                      >
                      <el-dropdown-item command="two"
                        >政策匹配</el-dropdown-item
                      >
                      <el-dropdown-item command="three"
                        >今日申报</el-dropdown-item
                      >
                    </div>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
            <!-- <div v-if="index == 2" class="flex align_center jus_center">
            <div class="fff size_18" :class="{ sele_cursor: poin === index }">
              融资服务
            </div>
            </div> -->
            <div
              v-if="index == 3"
              class="flex align_center jus_center nav_cursor"
              style="cursor:pointer;"
            >
              <div
                class="fff size_18 nav_title"
                :class="{ sele_cursor: poin === index }"
              >
                项目申报
              </div>
            </div>

            <div v-if="index == 4" class="flex align_center jus_center">
              <div class="flex align_center jus_center">
                <el-dropdown @command="golaw" placement="top-end">
                  <span
                    class="el-dropdown-link size_18 nav_title"
                    style="color: #fff"
                  >
                    企诉保障
                  </span>
                  <div :class="{ sele_cursor: poin == index }"></div>
                  <el-dropdown-menu slot="dropdown" class="downel">
                    <!-- <el-dropdown-item
                      v-for="item in zhengcelist"
                      :key="item.id"
                      command=item.id
                      >{{ item.title }}</el-dropdown-item
                    > -->
                    <div class="safeguarddown">
                      <el-dropdown-item command="pay"
                        >保障支付</el-dropdown-item
                      >
                      <el-dropdown-item command="servaappeal"
                        >服务诉求</el-dropdown-item
                      >
                      <el-dropdown-item command="feed"
                        >供方市场</el-dropdown-item
                      >
                      <el-dropdown-item command="demand"
                        >需方市场</el-dropdown-item
                      >
                      <el-dropdown-item command="response"
                        >对接状态</el-dropdown-item
                      >
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>

            <div
              v-if="index == 5"
              class="flex align_center jus_center nav_cursor"
              style="cursor:pointer;"
            >
              <div class="fff size_18 nav_title" style="text-align: center">
                融资需求
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
            </div>
            <div
              v-if="index == 6"
              style="cursor:pointer;"
              class="flex align_center jus_center nav_cursor"
            >
              <div class="fff size_18 nav_title" style="text-align: center">
                创客青海
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
            </div>

            <div
              v-if="index == 7"
              style="cursor:pointer;"
              class="flex align_center jus_center nav_cursor"
            >
              <div class="fff size_18 nav_title" style="text-align: center">
                服务产品
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
            </div>
            <!-- <div
              v-if="index == 8"
              style="cursor:pointer;"
              class="flex align_center jus_center nav_cursor"
            >
              <div class="fff size_18 nav_title" style="text-align: center">
                对接状态
                <div :class="{ sele_cursor: poin == index }"></div>
              </div>
            </div> -->
            <!-- <div
              v-if="index == 4"
              class="flex align_center jus_center nav_cursor"
              >
              <div class="fff size_18" :class="{ sele_cursor: poin === index }">
                创业服务
              </div>
              </div> -->
            <!-- <div
              v-if="index == 5"
              class="flex align_center jus_center nav_cursor"
             >
              <div class="fff size_18" :class="{ sele_cursor: poin === index }">
                人才培训
              </div>
            </div> -->
            <!-- <div
              v-if="index == 6"
              class="flex align_center jus_center nav_cursor"
            >
              <div class="fff size_18" :class="{ sele_cursor: poin === index }">
                法律服务
              </div>
            </div> -->

            <!-- <div
              v-if="index == 7"
              class="flex align_center jus_center nav_cursor"
            >
              <div class="fff size_18" :class="{ sele_cursor: poin === index }">
                个人申报
              </div>
            </div> -->
          </div>
        </div>
        <!-- 搜索框 -->
        <div
          class="topsearch"
          style="
            /* width: 573px; */
            height: 30px;
            background: #ffffff;
            border-radius: 6px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              background-color: #ffffff;
              height: 30px;
              line-height: 30px;
            "
          >
            <el-dropdown
              @command="golaw"
              style="width: 100px; text-align: center; padding-top: 3px"
            >
              <span class="el-dropdown-link" style="color:#000">
                {{ searchs }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="政策法规">政策法规</el-dropdown-item>
                <el-dropdown-item command="今日申报">今日申报</el-dropdown-item>
                <el-dropdown-item command="服务产品">服务产品</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="topsearch">
              <el-input v-model="topkeyword" style="width: 300px" placeholder="请输入" @keyup.enter.native="serach()" ></el-input>
            </div>
            <!-- <img
              @click="serach()"
              src="../../assets/image/Header/search.png"
              class="img"
              alt=""
            /> -->
            <span
              class="size_14 c417"
              style="padding: 0px 10px; display: inline-block; width: 60px"
              @click="serach()"
              >搜索</span
            >
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  // props: {
  //   phone: {
  //     type: String,
  //     // default: '0971 - 96868',
  //   },
  // },
  data() {
    return {
      phone: localStorage.phone ? "" : localStorage.phone,
      username:localStorage.name,
      userphone: localStorage.phone,
      token: localStorage.eleToken,
      // token: localStorage.Token,
      offsetHeight: 0,
      topkeyword: "",
      navList: [
        {
          name: "首页",
          url: "/",
        },
        {
          name: "通知公告",
          url: "/announcedetails",
        },
        {
          name: "政策法规",
          url: "/lawsregulations",
        },
        // {
          // name: "融资服务",
        // },
        {
          name: "项目申报",
        },
        {
          name: "企诉保障",
        },
        {
          name: "融资需求",
        },
        {
          name: "创客青海",
        },
        {
          name: "服务产品",
          url: "/serveProduct",
        },
        {
          name: "对接状态",
          url: "/response",
        },
        // {
        //   name: "创业服务",
        // },
        // {
        //   name: "人才培训",
        // },
        // {
        //   name: "法律服务",
        //   url: "/LegalService",
        // },
        // {
        //   name: "个人申报",
        //   url: "/declaration",
        // },
      ],
      searchs: "政策法规",
      type: localStorage.type,
      poin: localStorage.poin,
      ticket: {},
    };
  },
  methods: {
    serach() {
      if (this.searchs === "政策法规") {
        localStorage.setItem("poin", "2");
        this.$router.push({
          path: "/lawsregulations",
          query: { search: this.topkeyword },
        });
      } else if (this.searchs === "今日申报") {
        localStorage.setItem("poin", "2");

        this.$router.push({
          path: "/declaration",
          query: { search: this.topkeyword },
        });
      } else if (this.searchs === "服务产品") {
        localStorage.setItem("poin", "4");
        this.$router.push({
          path: "/serveProduct",
          query: { search: this.topkeyword },
        });
      }
    },
    gouser() {
      // console.log(localStorage.index, "localStorage.index");
      let index = localStorage.logintype;
      console.log(index, "index");
      if (index == 1) {
        this.$router.push({
          path: "/enterprise",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/personalcenter",
        });
      } else if (index == 3) {
        this.$router.push({
          path: "/serviceSpecialist",
        });
      }
    },
    golaw(item) {
      console.log(item, "item");
      if (item === item) {
        this.search = item;
      }
      let url;
      if (item === "one" || item === "two" || item === "three") {
        localStorage.setItem("poin", "2");
      }
      if (item === "login1") {
        localStorage.setItem("logintype", "1");
        this.toLoginVue();
      }
      if (item === "login2") {
        localStorage.setItem("logintype", "2");
        this.$router.push({
          path: "/sign",
          query: {
            num: 1,
          },
        });
      }
      if (item === "login3") {
        localStorage.setItem("logintype", "3");
        this.$router.push({
          path: "/sign",
          query: {
            num: 2,
          },
        });
      }
      if (item === "政策法规" || item === "今日申报" || item === "服务产品") {
        this.searchs = item;
      }
      console.log(item, "ite789m");

      switch (item) {
        case "one":
          url = "/lawsregulations";
          this.$router.push(url);
          break;
        case "two":
          url = "/matching";
          this.$router.push(url);
          break;
        case "three":
          url = "/declaration";
          this.$router.push(url);
          break;
        case "pay":
          window.open("https://sq.smeqh.cn/#/portal/paymentAppeal", "_blank");
          break;
        case "servaappeal":
          window.open(
            "https://sq.smeqh.cn/#/portal/enterpriseAppeal",
            "_blank"
          );
          break;
        case "feed":
          url = "/feed";
          localStorage.setItem("poin", "4");
          this.$router.push(url);
          break;
        case "demand":
          localStorage.setItem("poin", "4");
          url = "/demand";
          this.$router.push(url);
          break;
        case "response":
          localStorage.setItem("poin", "4");
          url = "/response";
          this.$router.push(url);
          break;
      }
    },
    getSetting() {
      this.$post("/setting", {}).then((res) => {
        this.phone = res.result.phone;
      });
    },
    toIndex() {
      let path = this.$route.path;
      if (path != "/") {
        this.$router.push("/");
      }
    },
    navigateTo(url, name) {
      console.log(url, name, "url, name");
      if (!url) {
        if (name == "人才培训") {
          this.$message.warning("当前模块开发中");
          return;
        }

        if (name == "融资服务") {
          window.open("https://rzx.smeqh.cn", "_blank");
          return;
        }

        if (name == "创业服务") {
          window.open("https://www.qh96868.cn", "_blank");
          return;
        }

        if (name == "项目申报") {
          window.open("https://shenbao.smeqh.cn/web/index.html", "_blank");
        }
        if (name == "融资需求") {
          window.open("https://rzx.smeqh.cn/web/#/", "_blank");
        }
        if (name == "创客青海") {
          window.open("http://www.cnmaker.org.cn", "_blank");
        }
      }
      let path = this.$route.path;
      console.log(path, "path");
      if (path != url) {
        this.$router.push(url);
      }
    },
    initHeader() {
      let path = this.$route.path;

      this.navList.map((item, index) => {
        if (path == item.url) {
          this.poin = index;
          localStorage.setItem("poin", index);
        } else {
          if (path == "/lawsdetails") {
            this.poin = 1;
          }
        }
      });
    },
    toLoginVue() {
      // console.log(this.baseUrl);
      location.href =
        "https://sso.smeqh.cn/sso/auth?redirect=" +
        encodeURIComponent(location.href) +
        "?back=" +
        encodeURIComponent(location.href) +
        "&origin=" +
        "user";
      // location.href =
      //   "http://sso.zhenchangkj.com:9000/sso/auth?redirect=" +
      //   encodeURIComponent(location.href) +
      //   // location.href +
      //   "?back=" +
      //   encodeURIComponent(location.href) +
      //   "&origin=" +
      //   4;

      // location.href =
      //   "http://sso.zhenchangkj.com:9000/sso/auth?redirect=" +
      //   encodeURIComponent(location.href) +
      //   // location.href +
      //   "?back=" +
      //   encodeURIComponent(location.href) +
      //   "&origin=" +
      //   "user";
    },
    // 退出
    loggin() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/logout?token=" + this.token).then((res) => {
            localStorage.removeItem('user');
            localStorage.clear();
            location.reload();
            this.$message({
              type: "success",
              message: "已退出",
            });
          });

          // this.$router.replace('/login')
          // if(this.$route.path == '/') {
          // 	this.$router.go(0)
          // }else {
          // 	this.$router.replace('/')
          // }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
  created() {
    this.initHeader();
    this.getSetting();
  },
  mounted() {
    console.log(this.$route.query, "this.$route.query.ticket");

    if (this.$route.query.ticket) {
      // document.cookie = `qhs-sso=${encodeURI(this.$route.query.ticket)}`
      const ticket = this.$route.query.ticket;
      console.log(ticket, "ticket");
      // this.baseUrl = "http://zk.zhenchangkj.com/v1";
      // this.baseUrl = 'http://jxx.zhenchangkj.com/v1';

      this.$get("/sso_login", { ticket: ticket })
        .then((res) => {
          console.log(res, "re45678s");
          this.phone = res.mobile;
          localStorage.setItem("phone", res.mobile);
          localStorage.setItem("name", res.name);
           

          if (res.code == 200) {
            localStorage.setItem("eleToken", res.data);
            localStorage.setItem("type", 1);

            //给token赋值
            this.token = res.data;
            console.log(this.$route.query.ticket, "this.$route.query.ticket");
            // console.log(this.$route.query.back);
            location.href = decodeURIComponent(this.$route.query.back);
            console.log(location.href, "location.href ");
            //  this.openFullScreen2();
          } else {
            // alert(res.msg);
            setTimeout(function () {
              location.href =
                "https://sso.smeqh.cn/sso/auth?redirect=" +
                encodeURIComponent(location.href) +
                "?back=" +
                encodeURIComponent(location.href) +
                "&origin=" +
                "user";
              // location.href =
              //   "http://sso.zhenchangkj.com:9000/sso/auth?redirect=" +
              //   encodeURIComponent(location.href) +
              //   "?back=" +
              //   encodeURIComponent(location.href) +
              //   "&origin=" +
              //   4;
              // location.href =
              //   "http://sso.zhenchangkj.com:9000/sso/auth?redirect=" +
              //   encodeURIComponent(location.href) +
              //   "?back=" +
              //   encodeURIComponent(location.href) +
              //   "&origin=" +
              //   "user";
            }, 800);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.offsetHeight = this.$refs.header.offsetHeight;
    // location.reload();
    this.$emit("getHeight", this.offsetHeight);
  },
};
</script>


<style lang="less" scoped>
@import "./header.css";
/deep/.el-dropdown-link {
  color: #fff;
}
.img {
  width: 30px;
  background: #ffffff;
  height: 30px;
  text-align: center;
  line-height: 30px;
  padding: 6px;
}

// ::v-deep.el-dropdown-menu {
//   margin-top: 12px;
//   display: flex;
//   top: 110px !important;
//   width: 100vw;
//   left: 0px !important;
// }
::v-deep.el-popper .popper__arrow {
  display: none;
}
::v-deep.el-dropdown-link {
  cursor: pointer;
  color: #808080;
}
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #ff7129;
}

.down {
  display: flex;
  margin-left: 400px;
}
.safeguarddown {
  display: flex;
  margin-left: 600px;
}
.nav_tit {
  width: 80px;
  height: 20px;
}
.downel {
  margin-top: 12px;
  display: flex;
  width: 100vw;
  top: 110px !important;
  left: 0px !important;
  margin-top: 12px;
}
.policy {
  margin-top: 12px;
  display: flex;
  width: 100vw;
  top: 110px !important;
  left: 0px !important;
  margin-top: 12px;
}
</style>
